function parallax () {
    const parallaxHover = document.querySelectorAll('[data-parallax="hover"]');

    if(parallaxHover.length > 0) {
        const speed = document.querySelectorAll('[data-speed]');

        parallaxHover.forEach(el=> {
            document.addEventListener('mousemove', (e)=> {
                let speed = el.getAttribute('data-speed');
                el.style.transform = `translate(${e.clientX / speed}px, ${e.clientY / speed}px)`;
            });
        });
    }
}

export default parallax;