import scroll from "./modules/scroll";
import parallax from "./modules/parallax";
import anim from "./modules/anim";
import slider from "./modules/slider";
import form from "./modules/form";
import nav from "./modules/nav";
import resizeTxt from "./modules/resizeTxt";
import anchors from "./modules/anchors";
import clipboard from "./modules/clipboard";

window.addEventListener('DOMContentLoaded', ()=> {
    scroll();
    parallax();
    anim();
    slider();
    form();
    nav();
    resizeTxt();
    anchors();
    clipboard();
});