function clipboard() {
    const postShare = document.querySelector('.post__share');
        
    if(postShare) {
        let ClipboardJS = require('clipboard'),
            linkElement = document.getElementById("copiedLink"),
            clipboardAlert = document.querySelector('.clipboard-alert');

        linkElement.addEventListener('click', (e)=> {
            e.preventDefault();
            clipboardAlert.classList.add('show');
            linkElement.style.pointerEvents = 'none';

            setTimeout(()=> {
                clipboardAlert.classList.remove('show');
                linkElement.style.pointerEvents = 'visible';
            }, 5000);
        });

        // Add Clipboard.js module
        new ClipboardJS(linkElement, {
            text: function() {
                return linkElement.href;
            }
        });
    }
}
export default clipboard;