function nav () {
    const header = document.querySelector('.header');
    const requestBtn = document.querySelector('.header__request');
    const burger = document.querySelector('.header__burger');
    const siteNav = document.querySelector('.site-nav');
    const siteNavLinks = document.querySelectorAll('.site-nav__link');

    function hideNav() {
        if(window.scrollY < 10 && header.classList.contains('home-page')) header.classList.remove('painted');
        requestBtn.classList.remove('hide');
        siteNavLinks.forEach(el=> el.classList.remove('show'));
        setTimeout(() => siteNav.classList.remove('show'), 300);
    }

    burger.addEventListener('click', (e)=> {
        burger.classList.toggle('active');

        if(!siteNav.classList.contains('show')) {
            if(!header.classList.contains('painted')) header.classList.add('painted');
            requestBtn.classList.add('hide');
            siteNav.classList.add('show');
            setTimeout(() => siteNavLinks.forEach(el=> el.classList.add('show')), 300);
        } else {
            hideNav();
        }
    });


    siteNavLinks.forEach(el=> {
        el.addEventListener('click', ()=> {
            hideNav();
            burger.classList.remove('active');
        });
    });
}

export default nav;