function scroll() {
    /*=======
    Header painted
    =======*/
    const header = document.querySelector('.header');

    if(header) {
        if(!header.classList.contains('painted')) {
            document.addEventListener('scroll', ()=> {
                window.scrollY > 10 ? header.classList.add('painted') : header.classList.remove('painted');
            });
        }
    }


    /*=======
    Anim parallax elements
    =======*/
    // const parallaxParent = document.querySelectorAll('[data-parallaxScroll="parent"]');

    // if(parallaxParent.length > 0) {
    //     parallaxParent.forEach((el, i)=> {
    //         const observerParallaxParent = new IntersectionObserver((entries, observer) => {
    //             entries.forEach((entry) => {
    //                 if (entry.isIntersecting) {
    //                     let i = 0;
    //                     let lastScrollTop = 0;

    //                     const offsetParallaxParent = entry.target.offsetTop;
    //                     const parallaxEl = entry.target.querySelector('[data-parallaxScroll="element"]');

    //                     document.addEventListener('scroll', (e)=> {
    //                         let st = window.pageYOffset || document.documentElement.scrollTop;

    //                         if (st > lastScrollTop) {
    //                             i--;
    //                             parallaxEl.style.transform = `translateY(${i}px)`;
    //                             i <= -100 ? i = -100 : i--; 
    //                         } else if (st < lastScrollTop) {
    //                             i++;
    //                             parallaxEl.style.transform = `translateY(${i}px)`;
    //                             i >= 10 ? i = 10 : i++;
    //                         }
    //                         lastScrollTop = st <= 0 ? 0 : st; 
    //                     });
    //                 }
    //             });
    //         }, {threshold: [0, 1]});
    //         observerParallaxParent.observe(el);
    //     });
    // }
}

export default scroll;