function slider () {
    /*=======
    Home page
    =======*/
    const sliderPortfolio = document.querySelector('.home__portfolio-slider');

    if(sliderPortfolio) {
        if(window.innerWidth <= 1240) {
            let slider = new Swiper(sliderPortfolio, {
                
                breakpoints: {
                    1: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        freeMode: {
                            enabled: false,
                        },
                        loop: true,
                        pagination: {
                            el: ".home__portfolio-slider-pagination",
                        },
                    },
                    768: {
                        slidesPerView: "auto",
                        spaceBetween: 20,
                        freeMode: {
                            enabled: true,
                        },
                        loop: false,
                    }
                },
            });
        }
    }
}

export default slider;