function resizeTxt () {
    /*========
    News page
    ========*/
    const resizeTxt = document.querySelectorAll('.resize-txt');

    if(resizeTxt.length) {
        resizeTxt.forEach((el, i) => {
            let text = el.innerHTML;
            let strLenght = text.length;
            
            function checkAndShorten(first, last) {
                text = text.substring(first, last);
            }

            if (strLenght > 150) {
                checkAndShorten(0, 150);
                el.innerText = text + '...';
                return;
            }
        });
    }
}

export default resizeTxt;