function anim () {
    /*===========
    Add animation on symbols txt
    ===========*/
    const animWrapper = document.querySelectorAll('[data-animWrapper]');
    
    if(animWrapper.length > 0) {
        animWrapper.forEach((el, i) => {
            //Add span tag
            let animSymbols = el.textContent;
            let animSymbolsArr = animSymbols.split(' ');
            let newAnimSymbols = animSymbolsArr.map((word, i) => `<span data-animTxt="fadeIn" data-animTime="${i}">${word}</span>`).join(' ');
            el.innerHTML = newAnimSymbols;
            
            //Add anim class
            const observerAnim = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        el.querySelectorAll('[data-animTxt]').forEach((animTxt, i) => {
                            let time = animTxt.getAttribute('data-animTime');
    
                            if (el.hasAttribute('data-animSlow')) {
                                setTimeout(() => {
                                    animTxt.classList.add('anim');
                                }, time * 200);
                            } else {
                                setTimeout(() => {
                                    animTxt.classList.add('anim');
                                }, time * 50);
                            }
                        });
                    }
                });
            }, { threshold: [.5] });
            observerAnim.observe(el);
        });
    }

    /*=======
    Add standard animation txt on post page
    =======*/
    const postInner = document.querySelector('.post__inner');

    if(postInner) {
        const postTxt = {
            p: [...postInner.querySelectorAll('p')],
            li: [...postInner.querySelectorAll('li')],
            h2: [...postInner.querySelectorAll('h2')],
        };
        
        const values = Object.values(postTxt);
        
        values.forEach(value => {
            value.forEach(element => {
                element.setAttribute('data-anim', 'topToBottom');
                element.setAttribute('data-animToScroll', '');
            });
        });
    }
    
    
    /*===========
    Add standard animation
    ===========*/
    const animEl = document.querySelectorAll('[data-animToScroll]');
    
    if(animEl.length > 0) {
        animEl.forEach(el=> {
            const observerAnim = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if(entry.isIntersecting) {
                        let time = entry.target.getAttribute('data-time');
    
                        if(!entry.target.getAttribute('data-time')) {
                            entry.target.classList.add('anim');
                        } else {
                            setTimeout(()=> {
                                entry.target.classList.add('anim');
                            }, time);
                        }
                    }
                });
            }, {threshold: [.3]});
            observerAnim.observe(el);
        });
    }
    
    const animInstantlyEl = document.querySelectorAll('[data-animInstantly]');

    if(animInstantlyEl.length > 0) {
        animInstantlyEl.forEach(el=> {
            let time = el.getAttribute('data-time');

            setTimeout(()=> {
                el.classList.add('anim');
            }, time);
        });
    }
}

export default anim;